import * as React from "react"
import { motion } from "framer-motion";

const VotoPartials = ({ dato, data }) => {

    const votopartials = {
      hidden: { opacity: 1, scale: 0 },
      visible: {
        opacity: 1,
        scale: 1,
        transition: {
          delayChildren: 0.8,
          staggerChildren: 0.2,
  
        }
      }
    };
  
    const boxpartial = {
      hidden: { y: 20, opacity: 0 },
      visible: {
        y: 0,
        opacity: 1
      }
    };
  
    return (
      <motion.div
        className="votopartials"
        variants={votopartials}
        initial="hidden"
        animate="visible"
      >
        {Object.keys(dato.ranking).map((item, index) => {
          return (
  
            <motion.div
              className="boxpartial"
              key={index}
              variants={boxpartial}>
              <div className="boxpartial-sx"><img className="spoon" src={data.spoon.publicURL} alt="spoon" /></div>
              <div className="boxpartial-sx">
                <div className="datoparziale">{dato.ranking[item]}%</div>
                <div className="datotitolo">{item}</div>
              </div>
            </motion.div>
  
          )
        })}
  
      </motion.div>
    )
  }

  export default VotoPartials