import * as React from "react"
import { motion } from "framer-motion";

const VotoTotale = (props) => {
    return (
        <motion.div
            className="votototale"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}

            transition={{
                type: "spring",
                stiffness: 260,
                damping: 50,
                delay: 1.5
            }}
        >

            <div className="voto-totale-titolo">Totale</div>
            <div className="voto-totale-dato">
                <img className="pot" src={props.pot} alt="pot" />
                <div className="innerbox">
                    <h1>{props.totale}%</h1>
                    <span>Organic & Fresh</span>
                </div>
            </div>
        </motion.div>
    )
}

export default VotoTotale