import * as React from "react"
import Layout from "../layouts/Layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import '../assets/scss/global.scss'
import './scheda.scss'
import { useStaticQuery, graphql, Link } from "gatsby"
import { motion } from 'framer-motion'
import VotoPartials from '../components/voto-partials/VotoPartials'
import VotoTotale from '../components/voto-totale/VotoTotale'
import Modal from 'react-modal';
import { useInView } from 'react-intersection-observer';
import ShareButtons from '../components/socialsharing/Socialsharing'
import { Helmet } from "react-helmet"



const getUrl = typeof window !== 'undefined' ? window.location.href : '';




const { useState } = React;

const Scheda = (props) => {

    const [modal, setModal] = useState({ open: false, id: null })

    function updateModal(id, stato) {

        let modal = { ...modal }
        modal.id = id
        modal.open = stato
        return modal

    }
    const data = useStaticQuery(graphql`
    {
      spoon: file(relativePath: { eq: "spoon.svg" }) {
                      relativePath
                      publicURL
                  }
      pot: file(relativePath: { eq: "pot.svg" }) {
                      relativePath
                      publicURL
                  }       
      sitomese: file(relativePath: { eq: "sitomese.svg" }) {
                      relativePath
                      publicURL
                  }                            
      ups: file(relativePath: { eq: "ups.svg" }) {
                      relativePath
                      publicURL
                  }                            
      downs: file(relativePath: { eq: "downs.svg" }) {
                      relativePath
                      publicURL
                  }    
      basso: file(relativePath: { eq: "30.svg" }) {
                      relativePath
                      publicURL
                  }                  
      medio: file(relativePath: { eq: "40-60.svg" }) {
                      relativePath
                      publicURL
                  }                  
      alto: file(relativePath: { eq: "60-80.svg" }) {
                      relativePath
                      publicURL
                  }                  
      massimo: file(relativePath: { eq: "80-100.svg" }) {
                      relativePath
                      publicURL
                  }                  
      link: file(relativePath: { eq: "link-solid.svg" }) {
                      relativePath
                      publicURL
                  }                  
                  }`)

    const { jamsite } = props.pageContext.data


    const [ref, inView] = useInView();
    const img1 = getImage(jamsite.screenshots.img1?.localFile)
    const img2 = getImage(jamsite.screenshots.img2?.localFile)
    const img3 = getImage(jamsite.screenshots.img3?.localFile)
    const img4 = getImage(jamsite.screenshots.img4?.localFile)
    const backImg = getImage(jamsite.immagine.localFile)
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{props.pageContext.data.title}</title>
                <meta name="description" content={jamsite.about} />
                <script type="application/ld+json">
                    {`

                        {
                            "@context": "https://schema.org",
                            "@type": "NewsArticle",
                            "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "${props.location.href}"
                            },
                            "headline": "${props.pageContext.data.title}",
                            "image": [
                            "${jamsite.immagine.localFile.absolutePath}"
                            ],

                            "author": {
                            "@type": "Person",
                            "name": "${jamsite.segnalatoDa}"
                            }
                        }

      `}
                </script>
            </Helmet>
            <div className="prodotto-backscheda" >
                <GatsbyImage image={backImg} alt={`background ${props.pageContext.data.title}`} />
            </div>

            <Layout>

                <motion.div
                    initial={{ y: 200, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20,
                        duration: 1,
                        delay: 0
                    }}
                    className="maincontainer scheda">

                    <motion.div
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20,
                            duration: 1,
                            delay: 0.2
                        }}

                        className="mainschedasx">
                        <a href={jamsite.url} target="_blank" rel="noopener"><h1 className="title"> <img className="link-to-site" src={data.link.publicURL} alt="to the site" />{props.pageContext.data.title} </h1></a>
                        <h2>About</h2>
                        <p>{jamsite.about}</p>
                        <h2>Categorie</h2>
                        <p>{jamsite.categorie.map((categoria, index) => {
                            return ((jamsite.categorie.length - 1) === index
                                ? categoria + " "
                                : categoria + "," + " ")
                        }
                        )}</p>
                        <h2>Ingredienti</h2>
                        <ul>
                            {jamsite.how.map((how) => {
                                return <li>{how}</li>
                            })}
                        </ul>
                        {jamsite.cucinatoDa &&
                            <>
                                <h2>Cucinato da:</h2>
                                {jamsite.urlSviluppatore ?
                                    <a className="cookedby" target="_blank" rel="noopener" href={jamsite.urlSviluppatore}>{jamsite.cucinatoDa}</a> :
                                    <p>{jamsite.cucinatoDa}</p>

                                }
                            </>
                        }
                        {jamsite.segnalatoDa &&
                            <>
                                <h2>Segnalato da</h2>
                                <p>{jamsite.segnalatoDa}</p>
                            </>
                        }

                    </motion.div>
                    <div className="social-wrapper-scheda">
                        <ShareButtons url={props.location.href} />
                    </div>
                    <motion.div
                        initial={{ y: 20, opacity: 0, zIndex: -1 }}
                        animate={{ y: 0, opacity: 1, zIndex: 0 }}
                        transition={{
                            type: "spring",
                            stiffness: 200,
                            damping: 20,
                            duration: 0.5,
                            delay: 1
                        }}
                        className="backbutton-wrapper-scheda">
                        <Link to="/">Back</Link>
                    </motion.div>
                    <div className="mainschedadx">

                        {(jamsite.ups && jamsite.downs) &&
                            <>
                                <motion.div className="ups"

                                    initial={{ y: 20, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{
                                        type: "spring",
                                        stiffness: 260,
                                        damping: 20,
                                        duration: 1,
                                        delay: 0.3
                                    }}

                                >
                                    <img src={data.ups.publicURL} alt="ups" />
                                    <p dangerouslySetInnerHTML={{ __html: jamsite.ups }} />
                                </motion.div>
                                <motion.div className="downs"
                                    initial={{ y: 20, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{
                                        type: "spring",
                                        stiffness: 260,
                                        damping: 20,
                                        duration: 1,
                                        delay: 0.3
                                    }}

                                >
                                    <img src={data.downs.publicURL} alt="downs" />
                                    <p dangerouslySetInnerHTML={{ __html: jamsite.downs }} />
                                </motion.div>
                            </>
                        }
                        <motion.div
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 260,
                                damping: 20,
                                duration: 1,
                                delay: 0.3
                            }}
                            className="note">
                            <h3>Note:</h3>
                            <div dangerouslySetInnerHTML={{ __html: jamsite.note }} />

                        </motion.div>

                    </div>

                    <motion.div className="screenshots"
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20,
                            duration: 1,
                            delay: 0.3
                        }}>
                        <h1>ScreenJamShots</h1>
                        {jamsite.screenshots.img1 &&
                            <div className="thumb" onClick={() => setModal(updateModal(1, true))}>
                                <GatsbyImage image={img1} alt={`sitescreen ${props.pageContext.data.title}`} />
                            </div>
                        }
                        {jamsite.screenshots.img2 &&
                            <div className="thumb" onClick={() => setModal(updateModal(2, true))}>
                                <GatsbyImage image={img2} alt={`sitescreen ${props.pageContext.data.title}`} />
                            </div>
                        }
                        {jamsite.screenshots.img3 &&
                            <div className="thumb" onClick={() => setModal(updateModal(3, true))}>
                                <GatsbyImage image={img3} alt={`sitescreen ${props.pageContext.data.title}`} />
                            </div>
                        }
                        {jamsite.screenshots.img4 &&
                            <div className="thumb" onClick={() => setModal(updateModal(4, true))}>
                                <GatsbyImage image={img4} alt={`sitescreen ${props.pageContext.data.title}`} />
                            </div>
                        }
                    </motion.div>

                    <div ref={ref} className={`${inView ? 'open' : ''} voto`}>
                        <div className="immaginevoto">
                            {jamsite.totale <= 40 ?
                                <img src={data.basso.publicURL} alt="score" /> :
                                jamsite.totale > 40 && jamsite.totale <= 70 ?
                                    <img src={data.medio.publicURL} alt="score" /> :
                                    jamsite.totale > 70 && jamsite.totale <= 90 ?
                                        <img src={data.alto.publicURL} alt="score" /> :
                                        jamsite.totale > 90 &&
                                        <img src={data.massimo.publicURL} alt="score" />
                            }
                        </div>
                        <VotoPartials dato={jamsite} data={data} />
                        <VotoTotale pot={data.pot.publicURL} totale={jamsite.totale} />
                    </div>

                </motion.div>

                <Modal
                    ariaHideApp={false}
                    isOpen={modal.open}>
                    <button onClick={() => setModal(updateModal(null, false))}>close</button>

                    {jamsite.screenshots.img1 && modal.id === 1 ?
                        <GatsbyImage image={img1} />
                        : jamsite.screenshots.img1 && modal.id === 2 ?
                            <GatsbyImage image={img2} />
                            : jamsite.screenshots.img1 && modal.id === 3 ?
                                <GatsbyImage image={img3} />
                                : jamsite.screenshots.img1 && modal.id === 4 &&
                                <GatsbyImage image={img4} />

                    }

                </Modal>

            </Layout>
        </>
    )
}

export default Scheda